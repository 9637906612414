import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../styles/style.css";
import featuremobile from "../assests/images/featuremobile.png";
import rightArrow from "../assests/images/rightArrow.png";
import cardbg from "../assests/images/cardbg.png";
import cardbgactive from "../assests/images/cardbghover.png";
import ReviewPage from "./Review";

function FeaturePage() {
  const options = {
    items: 1,
    loop: false,
    margin: 50,
    nav: false,
  };
  return (
    <>
      <div className="feature-page" id="Features">
        <div className="background-image"></div>
        <div className="feature-page-content">
          <h1 className="featurelogotext">Features</h1>
          <img
            className="iconimg"
            src={require("../assests/images/Featuresicon.png")}
            alt="Features Icon"
          />
        </div>
        <div className="p-4 w-lg-50 text-center container align-content-center">
          <p className="featurepara">
            Simple and easy app to locate local basketball courts. Connects
            trainers, parents, players, teams, and coaches with available local
            basketball facilities to use.
          </p>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="">
                <OwlCarousel className="owl-theme" {...options}>
                  <div class="item pt-5">
                    <div className="position-relative">
                      <div className="arrowImage">
                        <img src={rightArrow} alt="rightArrow" />
                      </div>
                      <div className="p-4 card featurecard">
                        <div className="cardText">
                          <h4>Features 1</h4>
                          <p>
                            Ease of use. Just enter your location and go from
                            there!
                          </p>
                        </div>
                        <img className="cardbg " src={cardbg} alt="" />
                        <img
                          className="cardbgactive"
                          src={cardbgactive}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="position-relative mt-2">
                      <div className="arrowImage">
                        <img src={rightArrow} alt="rightArrow" />
                      </div>
                      <div className="p-4 card featurecard">
                        <div className="cardText">
                          <h4>Features 2</h4>
                          <p>Train on your own time.</p>
                        </div>
                        <img className="cardbg " src={cardbg} alt="" />
                        <img
                          className="cardbgactive"
                          src={cardbgactive}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="position-relative mt-2">
                      <div className="arrowImage">
                        <img src={rightArrow} alt="rightArrow" />
                      </div>
                      <div className="p-4 card featurecard">
                        <div className="cardText">
                          <h4>Features 3</h4>
                          <p>No handling of payment- super easy!</p>
                        </div>
                        <img className="cardbg " src={cardbg} alt="" />
                        <img
                          className="cardbgactive"
                          src={cardbgactive}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="position-relative mt-2">
                      <div className="arrowImage">
                        <img src={rightArrow} alt="rightArrow" />
                      </div>
                      <div className="p-4 card featurecard">
                        <div className="cardText">
                          <h4>Features 4</h4>
                          <p>Grow your property business!</p>
                        </div>
                        <img className="cardbg " src={cardbg} alt="" />
                        <img
                          className="cardbgactive"
                          src={cardbgactive}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </OwlCarousel>
              </div>
            </div>

            <div className="col-lg-7 mt-5 mt-lg-0">
              <img src={featuremobile} alt="featuremobile" className="w-100" />
            </div>
          </div>
        </div>
      </div>
      <ReviewPage />
    </>
  );
}

export default FeaturePage;
