import React from "react";
import Downloadmobile from "../assests/images/mobileimg2.png";
import "../styles/style.css";
import ContactPage from "./Contact";

function DownloadPage() {
  const playStoreUrl =
    "https://play.google.com/store/apps/your-app-package-name";
  const appStoreUrl =
    "https://apps.apple.com/id/app/facility-rental/id6473168867";

  return (
    <>
      <div className="downloadpage-container" id="Download">
        <div className="container">
          <div className="d-flex row justify-content-center align-items-center">
            <div className="col-lg-6 text-center text-lg-start">
              <img src={Downloadmobile} alt="Mobile" className="img-fluid" />
            </div>
            <div className="col-lg-6 mt-5 mt-lg-0">
              <div>
                <div className="feature-page-content ps-3">
                  <h1 className="reviewicon">
                    Download <span className="">Our App</span>
                  </h1>
                  <img
                    className="Downloadimg "
                    src={require("../assests/images/Abouticon.png")}
                    alt="Features Icon"
                  />
                </div>
                <div className=" p-3 pt-4">
                  <p className="downloadpara">
                    Don't let gym booking slow you down.
                  </p>
                  <p className="downloadparagrph">
                    Get our mobile app to enjoy instant reservations, real-time
                    updates, and more—all in one place. Simplify your fitness
                    routine with hoopgng, now available on the App Store
                  </p>
                  <div className="d-flex flex-wrap">
                    {/* App Store link */}
                    <a
                      href={appStoreUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={require("../assests/images/Appimg.png")}
                        alt="Download on the App Store"
                      />
                    </a>
                    {/* Play Store link */}
                    <a
                      href={playStoreUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={require("../assests/images/Playstore.png")}
                        alt="Download on Google Play"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactPage />
    </>
  );
}

export default DownloadPage;
